.tag-form {
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    width: 50%;
}

.tag-overview {
    margin-bottom: 50px;
    border-radius: 20px;
    text-align: center;
}


.negotiated-price-tag {
    display: inline-block;
    padding: 0px 10px 2px;
    margin-left: 20px;
    width: fit-content;
    height: 28px;

    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12),
    inset 0px -6px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px 4px 0px 0px;
    p {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        color: #FFFFFF;
    }
}

.negotiated-price-tag-mb {
    width: fit-content;
    height: 22px;
    padding: 0px 10px;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    p {
        font-weight: 600;
        font-size: 12px;
    }
}