.signin {
    display: flex;
    flex-direction: column;
    align-items: center;
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .signin-form {
        margin-bottom: 5%;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        max-width: 100%;
        height: fit-content;
        box-shadow: 0px 4px 34px 7px rgba(0, 0, 0, 0.09);
        border-radius: 8px;
        >img {
            margin-top: 32px;
            margin-bottom: 23px;
        }
        >h4 {
            margin-bottom: 27px;
            width: 70%;
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 136%;
            text-align: center;
            color: #106E59;
        }
        .form {
            z-index: 1000;
            max-width: 320px;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding-bottom: 40px;
            .form-item {
                margin-bottom: 15px;
                width: 100%;
            }
            div.checkbox-input-container {
                align-items: center;
            }
            >p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #08362C;
                margin-bottom: 10px;
            }
        }
    }
}
@media screen and (max-width: 659px) {
    .signin .modalV2 .content {
        max-width: 90vw;
    }
}