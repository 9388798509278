@media (max-width: 489px) {
    .logo {
        display: none;
    }
    .mobile-logo {
        display: flex;
    }
    
}

@media (min-width: 490px) {
    .mobile-logo {
        display: none;
    }
    .logo {
        display: flex;
    }
    
}