.mapping_header {

    align-items: baseline;

    .selected_supplier {
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        gap: 5px;
        width: fit-content;
        margin-left: 0;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
        p {
            padding-top: 5px;
        }
        margin-right: 20px;
    }
}