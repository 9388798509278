.sub_categories_tags {
    margin: 20px 0;
}

.sub_categories_delete {
    display: flex;
    justify-content: space-between;
}

.sub_categories_alert {
    margin-top: 5px;
}

.categories_selector {
    display: block;
    width: 100%;
    margin: 10px 0;

    .categories_selector-input {
        margin-top: 10px;
    }
}